body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.upload-drag-area {
  border: 1px dashed #d9d9d9;
  border-radius: 4px;
  background-color: #f5f5f5;
  text-align: center;
  padding: 20px;
  cursor: pointer;
}

.upload-drag-area .ant-upload-drag-icon {
  margin-bottom: 8px;
  font-size: 15px;
}

.upload-drag-area .ant-upload-text {
  color: #595959;
  font-size: 10px;
}

.upload-drag-area .ant-upload-hint {
  color: #bfbfbf;
  font-size: 10px;
}

.Container {
  padding: 10px;
  width: 100%;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.myApp-Container {
  padding: 10px;
  width: 100%;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.TableContainer {
  overflow: hidden; 
  margin-bottom: 20px;
}

.ant-table {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.ant-table-body {
  overflow-y: auto;
}

.icon-container {
  background-color: #ffffff;
  padding: 3px;
  border-radius: 8px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin: -1px 2px 7px 9px;
}

.rotated-icon {
  transform: rotate(-90deg);
  color: rgb(112, 241, 119);
  font-size: 20px;
}

.rotated-icon-send {
  transform: rotate(-30deg);
  margin: 4px 0px 9px 2px;
  color: rgb(250, 250, 250);
  font-size: 20px;
}

.card-container {
  background-color: #7e708545;
  border-radius: 15px;
  box-shadow: 0 4px 8px rgba(151, 148, 148, 0.76);
  text-align: center;
  padding: 0px;
  height: 85%;
  width: 137px;
  margin: 0px 30px;
}

.card-container img {
  width: 60px;
  height: 60px;
  display: block;
  margin: 0 auto 10px auto;
}

.card-container a {
  color: black;
  font-weight: bold;
  font-size: 16px;
  text-decoration: none;
}

.card-container a:hover {
  color: #555;
  /* Slight color change on hover */
}

body .ant-table-small .ant-table-cell {
  padding: 4px 8px !important;
}

body .ant-table-small .ant-table-thead>tr>th {
  padding: 4px 8px !important;
}

body .ant-table-small .ant-table-row {
  height: auto !important;
}

body .ant-table-small {
  font-size: 12px !important;
}

:where(.css-dev-only-do-not-override-qnu6hi) .ant-input-search .ant-input-search-button {
  height: 24px;
  border: 1.5px solid rgb(23, 23, 23);
}

:where(.css-dev-only-do-not-override-qnu6hi) .ant-input-group .ant-input {
  height: 24px;
  border: 1.5px solid rgb(23, 23, 23);
}

.AddOrUpdateButton {
  width: 100%;
  font-size: 12px;
  height: 25px;
}

.DeactivateButton {
  width: 100%;
  font-size: 12px;
  height: 25px;
  /* border-color: red; */
  border: 1.5px solid rgb(255, 0, 0);
  color: red;
  padding: 0 10px;
}

.disabledButton {
  /* background-color: black; */
  border: 1.5px solid rgb(0, 0, 0);
  color: white;
  border-color: black;
}

.fs-10 {
  font-size: 10px !important;
}

.fs-12 {
  font-size: 12px !important;
}

.fs-15 {
  font-size: 15px !important;
}

.background-image {
  position: fixed;
  height: 100vh;
  width: 100vw;
  background-image: url('/public/ExdionBackground1.jpg');
  background-size: 98% 95%;
  background-position: left;
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  margin: -30px 0px 0px 0px;
  text-align: center;
}

.group-user-styles {
  border: 1px solid black;
  padding: 5px;
  border-radius: 5px;
  min-width: 35%;
}

.groupUserBorder {
  border: 1px solid black;
  border-radius: 10px;
  padding-left: 15px;
}

.p-2{
  padding: 2px !important;
}

.p-5{
  padding: 5px !important;
}

.flex-wrap{
  display: flex;
  flex-wrap: wrap;
  justify-content: start;
  gap: 20px;
}

.flex-btw-wrap{
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  /* gap: 20px; */
}

.flex-nowrap{
  display: flex;
  justify-content: start;
  gap: 20px;
}

.align-i-c{
  align-items: center;
}

.align-i-fs{
  align-items:flex-start;
}

.g-20{
  gap: 20px !important;
}

.f-key{
  font-size: small;
  font-weight: 500;
}

.w-30{
  width: 30% !important;
}

.userResponse .ant-card .ant-card-body{
  padding: 10px !important;
}

.fw-bold{
  font-weight: bold;
}