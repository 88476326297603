   .chat-loader {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
  }
  
  .dot-typing {
    width: 7px;
    margin-left: 20px; 
    aspect-ratio: 1; 
    border-radius: 50%; 
    animation: l5 1s infinite linear alternate; 
  }

  @keyframes l5 {
    0% {
      box-shadow: 20px 0 #000, -20px 0 #007bff; 
      background: #000; 
    }
    33% {
      box-shadow: 20px 0 #000, -20px 0 #007bff; 
      background: #007bff; 
    }
    66% {
      box-shadow: 20px 0 #007bff, -20px 0 #000; 
      background: #007bff; 
    }
    100% {
      box-shadow: 20px 0 #007bff, -20px 0 #000; 
      background: #000; 
    }
  }